"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameBoard = void 0;
const React = __importStar(require("react"));
const react_jss_1 = require("react-jss");
const board_react_1 = require("@shoki-web/board-react");
const DynamicAspectRatioComponent_1 = require("./DynamicAspectRatioComponent");
const GameBoardContext_1 = require("./GameBoardContext");
const createClickEvent = (location) => ({ location });
const createDropPieceEvent = (id, location) => ({ id, location });
function useEvents({ onClick, onDropPiece, }) {
    const onClickBoard = React.useCallback(({ x, y }) => {
        if (!onClick) {
            return;
        }
        onClick(createClickEvent({ locationType: "board", x, y }));
    }, [onClick]);
    const onClickBench = React.useCallback(({ x }) => {
        if (!onClick) {
            return;
        }
        onClick(createClickEvent({ locationType: "bench", x }));
    }, [onClick]);
    const onDropBoard = React.useCallback(({ id, x, y }) => {
        if (!onDropPiece) {
            return;
        }
        onDropPiece(createDropPieceEvent(id, { locationType: "board", x, y }));
    }, [onDropPiece]);
    const onDropBench = React.useCallback(({ id, x }) => {
        if (!onDropPiece) {
            return;
        }
        onDropPiece(createDropPieceEvent(id, { locationType: "bench", x }));
    }, [onDropPiece]);
    return {
        onClickBoard,
        onClickBench,
        onDropBoard,
        onDropBench,
    };
}
function useRenderers({ renderBoardPiece, renderBenchPiece, }) {
    const { board, bench } = (0, GameBoardContext_1.useGameBoard)();
    const boardPieceRenderer = React.useMemo(() => (item) => {
        const piece = item;
        const draggable = !board.locked;
        return {
            item: renderBoardPiece(piece),
            draggable,
        };
    }, [board.locked, renderBoardPiece]);
    const benchPieceRenderer = React.useMemo(() => (item) => {
        const piece = item;
        const draggable = !bench.locked;
        return {
            item: renderBenchPiece(piece),
            draggable,
        };
    }, [bench.locked, renderBenchPiece]);
    return { boardPieceRenderer, benchPieceRenderer };
}
const useStyles = (0, react_jss_1.createUseStyles)({
    gameBoard: {
        "height": "100%",
        "width": "100%",
        "display": "flex",
        "flexDirection": "column",
        "justifyContent": "center",
        "& .tile .dark": {
            background: "#38b764",
        },
        "& .tile .light": {
            background: "#a7f070",
        },
    },
    board: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "78%",
    },
    bench: {
        "position": "absolute",
        "bottom": "0",
        "width": "100%",
        "height": "20%",
        "& .tile": {
            background: "#9e9e9e !important",
            boxShadow: "inset 0 0 2px #404040",
        },
    },
    benchBoard: {
        position: "absolute",
        bottom: "0",
    },
});
function GameBoard({ renderBoardPiece, renderBenchPiece, renderTileBackground, onClick, onDropPiece, }) {
    const { board, bench } = (0, GameBoardContext_1.useGameBoard)();
    const { boardPieceRenderer, benchPieceRenderer } = useRenderers({
        renderBoardPiece,
        renderBenchPiece,
    });
    const { onClickBoard, onClickBench, onDropBoard, onDropBench } = useEvents({
        onClick,
        onDropPiece,
    });
    const styles = useStyles();
    const ref = React.useRef(null);
    /**
     * How much space, in tiles, to leave between the board and the bench.
     */
    const SPACER_TILE_HEIGHT = 0.2;
    const aspectRatio = board.size.width /
        (board.size.height + bench.size.height + SPACER_TILE_HEIGHT);
    return (React.createElement("div", { className: styles.gameBoard, ref: ref },
        React.createElement(DynamicAspectRatioComponent_1.DynamicAspectRatioComponent, { aspectRatio: aspectRatio, containerRef: ref },
            React.createElement("div", { className: styles.board },
                React.createElement(board_react_1.BoardGrid, { state: board, onDropItem: onDropBoard, onClickTile: onClickBoard, renderItem: boardPieceRenderer, renderTileBackground: renderTileBackground })),
            React.createElement("div", { className: styles.bench },
                React.createElement(board_react_1.BoardGrid, { state: bench, onDropItem: onDropBench, onClickTile: onClickBench, renderItem: benchPieceRenderer, boardClassName: styles.benchBoard, renderTileBackground: () => null })))));
}
exports.GameBoard = GameBoard;
