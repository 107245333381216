"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardShop = void 0;
const React = __importStar(require("react"));
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const react_jss_1 = require("react-jss");
const GamemodeSettingsContext_1 = require("../../GamemodeSettingsContext");
const DynamicAspectRatioComponent_1 = require("../../gameBoard/DynamicAspectRatioComponent");
const layout_1 = require("../../layout");
const button_1 = require("../button");
const cardSelector_1 = require("./cardSelector");
const useStyles = (0, react_jss_1.createUseStyles)({
    container: {
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    aspectWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    purchase: {
        background: "#38b764",
        padding: "0 1.5em",
        marginTop: "-2.5em",
        zIndex: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: "4px",
        fontWeight: 700,
        cursor: "pointer",
        fontFamily: "Arial, sans-serif",
    },
    controls: {
        padding: 0,
        marginTop: "0.25em",
        userSelect: "none",
    },
});
const CardShop = ({ cards, ownedDefinitionIds, money, isLocked = false, onReroll, onToggleLock, onBuy, }) => {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const onBuyCurrentCard = () => onBuy && selectedIndex !== null && onBuy(selectedIndex);
    const { rerollCost } = (0, GamemodeSettingsContext_1.useGamemodeSettings)();
    const ref = React.useRef(null);
    const ASPECT_RATIO = 1 / 1;
    return (React.createElement(layout_1.Layout, { className: classes.container, direction: "column", ref: ref },
        React.createElement(DynamicAspectRatioComponent_1.DynamicAspectRatioComponent, { aspectRatio: ASPECT_RATIO, containerRef: ref, className: classes.aspectWrapper },
            React.createElement(cardSelector_1.CardSelector, { cards: cards, money: money, onSelectCard: setSelectedIndex, ownedDefinitionIds: ownedDefinitionIds }),
            React.createElement(layout_1.Layout, { className: classes.controls, direction: "row", justifyContent: "space-between" },
                React.createElement(button_1.Button, { type: "primary", onClick: onReroll, disabled: money < rerollCost },
                    React.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faArrowsRotate }),
                    "\u00A0$",
                    rerollCost),
                React.createElement("div", { className: classes.purchase, onClick: onBuyCurrentCard },
                    React.createElement("span", null, "Purchase")),
                React.createElement(button_1.Button, { type: "primary", onClick: onToggleLock }, isLocked ? "Unlock" : "Lock")))));
};
exports.CardShop = CardShop;
