"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DroppableTile = void 0;
const React = __importStar(require("react"));
const react_dnd_1 = require("react-dnd");
const context_1 = require("../../context");
function DroppableTile({ x, y, onDrop, onClick }) {
    const belowPieceLimit = (0, context_1.useBelowPieceLimit)();
    const pieces = (0, context_1.usePieces)();
    const [{}, drop] = (0, react_dnd_1.useDrop)({
        accept: "BoardItem",
        drop: ({ id }, monitor) => {
            if (!onDrop) {
                return;
            }
            onDrop({ id, x, y });
        },
        canDrop: ({ id }) => {
            const pieceIsFromSameBoard = Boolean(pieces[id]);
            return belowPieceLimit || pieceIsFromSameBoard;
        },
        collect: (monitor) => ({
            canDrop: !!monitor.canDrop(),
            isDragging: !!monitor.getItem(),
        }),
    });
    const onTileClick = React.useCallback(() => {
        if (!onClick) {
            return;
        }
        onClick({ x, y });
    }, [onClick, x, y]);
    return (React.createElement("div", { ref: drop, style: {
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
        }, onClick: onTileClick }));
}
exports.DroppableTile = DroppableTile;
