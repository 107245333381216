"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTileBackgroundRenderer = exports.usePiecePositions = exports.usePieces = exports.useBelowPieceLimit = exports.useBoardState = exports.BoardContextProvider = void 0;
const react_1 = require("react");
const board_1 = require("@shoki/board");
const BoardContext = (0, react_1.createContext)(null);
BoardContext.displayName = "BoardContext";
exports.BoardContextProvider = BoardContext.Provider;
const useBoard = () => (0, react_1.useContext)(BoardContext);
const useBoardState = () => useBoard().state;
exports.useBoardState = useBoardState;
const useBelowPieceLimit = () => {
    const boardState = (0, exports.useBoardState)();
    if (!boardState) {
        return false;
    }
    return (boardState.pieceLimit === null ||
        board_1.BoardSelectors.isBelowPieceLimit(boardState));
};
exports.useBelowPieceLimit = useBelowPieceLimit;
const usePieces = () => {
    const boardState = (0, exports.useBoardState)();
    if (!boardState) {
        return {};
    }
    return boardState.pieces;
};
exports.usePieces = usePieces;
const usePiecePositions = () => {
    const boardState = (0, exports.useBoardState)();
    if (!boardState) {
        return {};
    }
    return boardState.piecePositions;
};
exports.usePiecePositions = usePiecePositions;
function useTileBackgroundRenderer() {
    var _a;
    const context = useBoard();
    return (_a = context.tileBackgroundRenderer) !== null && _a !== void 0 ? _a : null;
}
exports.useTileBackgroundRenderer = useTileBackgroundRenderer;
