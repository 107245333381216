"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Card = void 0;
const React = __importStar(require("react"));
const react_jss_1 = require("react-jss");
const layout_1 = require("../../layout");
const display_1 = require("../display");
const useStyles = (0, react_jss_1.createUseStyles)({
    card: {
        boxSizing: "border-box",
        fontFamily: "Arial, sans-serif",
        color: "#fff",
        textAlign: "center",
        userSelect: "none",
        background: ({ alreadyOwned }) => alreadyOwned ? "#587261" : "#303030",
        borderColor: ({ alreadyOwned }) => alreadyOwned ? "#587261" : "#303030",
        paddingBottom: "0.5em",
        borderWidth: "10px",
        borderStyle: "solid",
        borderRadius: "10px",
    },
    name: {
        marginBottom: 0,
        marginTop: "-0.5em",
        paddingTop: "0.25em",
        paddingBottom: "0.25em",
        paddingLeft: "1em",
        paddingRight: "1em",
        background: "rgb(48, 48, 48)",
        borderRadius: "5px",
        fontSize: "1em",
        fontWeight: 700,
    },
    typeIndicator: {
        zIndex: 20,
        marginTop: "-0.5em",
        marginLeft: "-0.5em",
        width: "24px",
        height: "24px",
    },
    cost: {
        position: "absolute",
        padding: "0.25em 0.5em",
        top: "-5%",
        right: "5%",
        background: "#38b764",
        borderRadius: "3px",
        lineHeight: "1.5em",
    },
    cardMeta: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
    },
    metaItem: {
        fontSize: "0.75rem",
        fontWeight: 700,
        textTransform: "uppercase",
        marginBottom: "0.25em",
    },
    cardImage: {
        width: "80%",
        height: "80%",
        position: "relative",
        left: "10%",
        paddingBottom: "1em",
    },
    cardInfo: {
        background: "#808080",
        paddingBottom: "1em",
        borderRadius: "4px",
        marginTop: "-0.5em",
    },
    cardInfoTopBar: {
        marginLeft: "1em",
        marginRight: "1em",
        marginBottom: "0.5em",
    },
});
const Card = (props) => {
    const classes = useStyles(props);
    const { card: { name, definitionId, type, cost, class: cardClass }, onClick, disabled = false, } = props;
    return (React.createElement("div", { className: classes.card, onClick: !disabled ? onClick || undefined : undefined },
        React.createElement(layout_1.Layout, { direction: "column", noSpacer: true },
            React.createElement("div", { className: classes.cost },
                React.createElement("span", null,
                    "$",
                    cost)),
            React.createElement(display_1.CreatureImage, { definitionId: definitionId, className: classes.cardImage }),
            React.createElement("div", { className: classes.cardInfo },
                React.createElement(layout_1.Layout, { direction: "row", noSpacer: true, className: classes.cardInfoTopBar },
                    React.createElement("div", { className: classes.typeIndicator },
                        React.createElement(display_1.TypeIndicator, { type: type })),
                    React.createElement("h2", { className: classes.name }, name)),
                React.createElement("div", { className: classes.cardMeta },
                    React.createElement("span", { className: classes.metaItem }, cardClass),
                    React.createElement("span", { className: classes.metaItem }, type))))));
};
exports.Card = Card;
